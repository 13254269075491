.cut,
.episode {
  transition: clip-path 0.2s ease;
}

.cut.a,
.episode.a {
    clip-path: polygon(
        0% 0%,
        calc(100% - 80px) 2%,
        100% 80px,
        100% 96%,
        10% 100%,
        0% 100%
    );
}
.cut.a:hover,
.episode.a:hover {
    --notchSize: 50px;
    clip-path: polygon(
        0% 0%,
        calc(100% - var(--notchSize)) 0%,
        100% var(--notchSize),
        100% 100%,
        var(--notchSize) 95%,
        0% calc(100% - var(--notchSize))
    );
}

.cut.b,
.episode.b {
    clip-path: polygon(
        1% 6%,
        85% 0%,
        100% 15%,
        100% 100%,
        50% 98%,
        0% 95%
    );
}
.cut.b:hover,
.episode.b:hover {
    clip-path: polygon(
        0% 5%,
        65% 2%,
        100% 15%,
        100% 95%,
        30% 100%,
        2% 100%
    );
}


.cut.c,
.episode.c {
    clip-path: polygon(
        0% 6%,
        20% 0%,
        98% 0%,
        100% 60%,
        90% 100%,
        6% 90%,
        0% 80%
    );
}
.cut.c:hover,
.episode.c:hover {
    clip-path: polygon(
        2% 0%,
        50% 2%,
        98% 10%,
        100% 60%,
        98% 98%,
        1% 93%,
        0% 90%
    );
}

.cut.d,
.episode.d {
    clip-path: polygon(
        0% 8%,
        50% 0%,
        98% 2%,
        100% 15%,
        98% 60%,
        90% 100%,
        2% 95%
    );
}
.cut.d:hover,
.episode.d:hover {
    clip-path: polygon(
        0% 2%,
        20% 0%,
        90% 6%,
        100% 15%,
        100% 60%,
        98% 93%,
        0% 100%
    );
}

.cut.e,
.episode.e {
    clip-path: polygon(
        0% 6%,
        6% 3%,
        18% 0%,
        100% 3%,
        99% 90%,
        55% 100%,
        0% 96%
    );
}
.cut.e:hover,
.episode.e:hover {
    clip-path: polygon(
        0% 6%,
        7% 5%,
        50% 0%,
        95% 3%,
        99% 98%,
        35% 100%,
        1% 95%
    );
}