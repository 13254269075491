.episode {
  margin-top: 30px;
  width: 432px;
  height: 432px;
}
.image-area {
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.info-area {
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 16px;
  transition: background-color 0.2s ease;
}

.info-area:hover {
  background: rgba(0, 0, 0, 0.8);
}

.episode .info-title {
  margin: 0 25px;
  text-align: center;
}
.episode h3 {
  font-weight: bold;
  font-size: 1.3em;
  margin: 0;
}

.episode .summary {
  font-size: 0.95em;
  padding: 16px;
}

.episode .more {
  font-size: 1em;
}


@media screen and (max-width: 400px) {
  .episode {
    margin-top: 30px;
    width: 332px;
    height: 432px;
    transition: clip-path 0.2s ease;
  }
  .info-area {
    width: 300px;
    height: 400px;
  }
}