.tag {
  margin:0 5px;
  font-size: 1.2em;
  display: inline-block;
  padding: 8px;
  color: #c27a22;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border:none;
  outline: none;
  line-height: 1em;
}

.tag:hover {
  color: #ffffff;
}

.tag.selected {
  color: white;
}

.tag:hover > .tag-name{
  text-decoration: underline;
}
