a {
  color: #c27a22;
  font-weight: bold;
  text-decoration: none;
}
a:hover {
  color: #ffffff;
  text-decoration: underline;
}

a.dark {
  color: #ffffff;
  font-weight: bold;
  text-decoration: underline;
}
a.dark:hover {
  text-decoration: none;
}
a.white-link {
  color: #ffffff
}

.app {
  background: #c27a22;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.header {
  background: #131313;
  color: #ffffff;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transform: rotate(-3deg);
  padding-left:40px;
  margin-left:-40px;
  padding-top:200px;
  margin-top:-200px;
  margin-bottom:50px;
  z-index:1;
}

.header-main {
  display: flex;
  align-items: flex-end;
}

a.nav-selected {
  color: white;
}

.tags {
  padding-left:40px;
  margin-left:-40px;
  margin-bottom:-5px;
  background-color: #351e03;
  min-height: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.tags.about,
.tags.episode-details {
  background: #c27a22;  
}

.under-slanted {
  flex-grow: 1;
  position: relative;
  overflow: auto;
  z-index: 0;
  min-height: 200px;
  margin-top:-100px;
  padding-top: 100px;
}
.under-slanted.about,
.under-slanted.episode-details {
  background-color: #351e03;
}

.links > a {
  margin:0 5px;
  font-size: 1.5em;
  display: inline-block;
  padding: 8px;
  font-weight: normal;
}

.divider {
  margin:0 5px;
  height: 1.5em;
  border-left: 2px solid white;
}

.logo {
  width: 420px;
}

.footer {
  background: #351e03;
  color: #ffffff;
  flex-shrink: 0;
  padding-top:10px;
  text-align: right;
  clip-path: polygon(
      100% 0%,
      100% 100%,
      calc(100% - 335px) 100%
  );
  margin-top: -63px;
  font-weight:bold;
}

.footer-content {
  padding: 8px;
  padding-top:24px;
  background: #131313;
  clip-path: polygon(
      100% 0%,
      100% 100%,
      calc(100% - 285px) 100%
  );
}

.footer.about,
.footer.episode-details {
  background: #c27a22;
}

@media screen and (max-width: 750px) {
  .logo {
    width: 300px;
  }
  .tags {
    padding-left: 40px;
    justify-content: center;
  }
  .divider {
    display: none;
  }
  .links > a {
    margin:0 2px;
    font-size: 1.5em;
    display: inline-block;
    padding: 4px;
  }
}

@media screen and (max-width: 400px) {
  .divider {
    display: block;
    height:0;
    border-left: none;
  }
  .logo {
    width: 175px;
  }
  .links > a {
    margin:0 2px;
    font-size: 1.2em;
    display: inline-block;
    padding: 4px;
  }
}