.content-holder {
  padding: 8px 32px;
  padding-bottom: 45px;
}

.description {
  font-size: 1.5em;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  margin:0;
  padding:0;
}

.content-holder .episodes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
}
.content-holder .episodes > * {
  margin: 8px 8px;
}

@media screen and (max-width: 615px) {
  .content-holder {
    padding: 4px;
  }
  .content-holder .episodes > * {
    margin: 8px 0px;
  }
  .description {
    font-size: 1.3em;
  }
}
