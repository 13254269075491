.about-holder {
    padding: 8px 32px;
    font-size: 1.2em;
    color: #ffffff;
    margin-bottom: 35px;
}

.about-holder > h2 {
    font-size: 1.6em;
}

.host-info {
    display: flex;
    justify-content: space-between;
}

.cut-image {
    clip-path: 
      polygon(
        0% 35%,
        50% 5%,
        100% 0%,
        100% 100%,
        20% 90%
      );
    transition: clip-path 0.2s ease;
}

.cut-image:hover {
    clip-path: 
      polygon(
        5% 15%,
        60% 0%,
        100% 4%,
        100% 95%,
        5% 95%
      );
}


@media screen and (max-width: 615px) {
  .host-info {
    flex-direction: column;
  }
}
